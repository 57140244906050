// make webpack serve bootstrap on localhost and fetch it on test client instead of importing

import { WidgetConfig } from '../../testclient/src/store/types';
import { createIframe, renderApp, getContainerElement } from './render';
import { Event, Listener, Messenger } from './messenger';
import envConfig from '../envConfig';

const cdnUrl = envConfig[ENV_NAME].cdnURL;
const urlObj = new URL(cdnUrl);

export class IngridUpsellWidgetAPI {
  messenger = new Messenger({ origin: urlObj.origin });

  merchantState?: WidgetConfig;
  iframe?: HTMLIFrameElement;

  addListener(event: Event, listener: Listener) {
    this.messenger.addEventListener(event, listener);
  }

  render(elementId: string, state: WidgetConfig) {
    const container = getContainerElement(elementId);

    this.iframe = createIframe(cdnUrl);
    this.iframe.onload = () => {
      this.updateCart(state);
    };

    const [activate, cancel] = renderApp(this.iframe, container);

    this.messenger.addEventListener('ready', activate);
    this.messenger.addEventListener('error', () => {
      console.error('Upsell widget raised a network error!');
      cancel();
    });
    this.messenger.addEventListener('size_changed', size => {
      if (!this.iframe) {
        return;
      }
      this.iframe.style.height = `${size.height}px`;
    });
  }

  updateCart(state: WidgetConfig) {
    const payload = {
      type: 'update_cart',
      data: state,
    };

    if (this.iframe) {
      this.messenger.sendMessageToIframe(this.iframe, payload, urlObj.origin);
    }
    // mock call to BE and handle network errors here
    // on response call price_changed event
  }
}

window._ingridUpsellWidgetApi = new IngridUpsellWidgetAPI();
