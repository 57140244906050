type EnvConfigGlobal = Record<string, EnvConfig>;

interface EnvConfig {
  api: string;
  cdnURL: string;
}

const config: EnvConfigGlobal = {
  local: {
    api: 'http://localhost:3000/v1',
    cdnURL: 'http://localhost:3000/',
  },
  development: {
    api: 'https://api-development.ingrid.com/v1',
    cdnURL: 'https://cdn-development.ingrid.com/upsell-widget/dist/',
  },
  stage: {
    api: 'https://api-stage.ingrid.com/v1',
    cdnURL: 'https://cdn-stage.ingrid.com/upsell-widget/dist/',
  },
  production: {
    api: 'https://api.ingrid.com/v1',
    cdnURL: 'https://cdn.ingrid.com/upsell-widget/dist/',
  },
};

export default config;
