export const styleHTML = `
  @keyframes fancygradient {
    from {
      background-position-x: 100%;
      background-position-y: 0;
    }
    to {
      background-position-x: 0;
      background-position-y: 0;
    }
  }

  .ingrid-skel {
    width: 100%;
    max-width: 600px;
    padding: 16px;
  }

  .ingrid-skel-row {
    width: 100%;
    display: flex;
  }

  .ingrid-skel-line {
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    background: linear-gradient(90deg,hsla(0,0%,74.5%,.2) 25%,hsla(0,0%,50.6%,.24) 37%,hsla(0,0%,74.5%,.2) 63%);
    background-size: auto;
    background-size: 400% 100%;
    animation: fancygradient 1.4s ease infinite;
  }

  .ingrid-skel-line-1 {
    height: 14px;
    width: 66%;
    margin-right: 4%;
  }

  .ingrid-skel-line-2 {
    height: 14px;
    width: 30%;
  }

  .ingrid-skel-line-3 {
    height: 10px;
    width: 100%;
    margin: 8px 0 0 0;
  }

  .ingrid-skel-line-4 {
    height: 12px;
    width: 15%;
    margin: 8px 0 0 85%;
  }
`;

export const divHTML = `
  <div class="ingrid-skel">
    <div class="ingrid-skel-row">
      <div class="ingrid-skel-line ingrid-skel-line-1"></div>
      <div class="ingrid-skel-line ingrid-skel-line-2"></div>
    </div>
    <div class="ingrid-skel-row">
      <div class="ingrid-skel-line ingrid-skel-line-3"></div>
    </div>
    <div class="ingrid-skel-row">
      <div class="ingrid-skel-line ingrid-skel-line-4"></div>
    </div>
  </div>
`;
