import { styleHTML, divHTML } from './skeleton';

export function createIframe(url: string): HTMLIFrameElement {
  const iframe = document.createElement('iframe');
  // Indicate who created the iframe to secure postMessage communication.
  iframe.src = `${url}index.html?creator=${window.location.origin}`;
  return iframe;
}

/*
 * Returns a function to call and display iframe when everything is
 * initialized.
 */
export function renderApp(
  iframe: HTMLIFrameElement,
  container: HTMLElement
): [() => void, () => void] {
  iframe.style.border = 'unset';
  iframe.style.height = '0';
  iframe.style.width = '0';
  iframe.style.maxWidth = '600px';

  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = styleHTML;

  const skeleton = document.createElement('div');
  skeleton.innerHTML = divHTML;

  document.head.appendChild(style);
  container.appendChild(skeleton);
  container.appendChild(iframe);

  // remove skeleton and iframe
  const hideSkeleton = () => {
    if (container.contains(skeleton)) {
      container.removeChild(skeleton);
    }
    if (document.head.contains(style)) {
      document.head.removeChild(style);
    }
  }

  // Substitute skeleton for iframe
  const activate = () => {
    hideSkeleton();
    // aproximate height value.
    iframe.style.height = '80px';
    iframe.style.width = '100%';
  };

  return [activate, hideSkeleton];
}

export function getContainerElement(elementId: string): HTMLElement {
  const container = document.getElementById(elementId);
  if (container) {
    return container;
  }

  throw `The container with id "${elementId}" was not found!`;
}
