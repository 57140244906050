export type Event = 'ready' | 'free_delivery_reached' | 'error' | 'size_changed';
export type Listener = (value?: any) => void;

export interface MessengerProps {
  origin: string;
}

export class Messenger {
  eventListeners: Record<Event, Listener[]> = {
    ready: [],
    free_delivery_reached: [],
    error: [],
    size_changed: [],
  };

  constructor({ origin }: MessengerProps) {
    window.addEventListener(
      'message',
      event => event.origin === origin && this.iframeMessageListener(event)
    );
  }

  iframeMessageListener = (event: MessageEvent) => {
    const { type, value } = event.data;

    this.sendMessageToMerchant(type, value);
  };

  addEventListener = (event: Event, listener: Listener) => {
    this.eventListeners[event].push(listener);
  };

  // TODO: type messages data so we can remove any from here
  sendMessageToIframe = (iframe: HTMLIFrameElement, payload: any, url: string) => {
    if (iframe.contentWindow) {
      iframe.contentWindow.postMessage(payload, url);
    } else {
      // eslint-disable-next-line no-console
      console.warn('No contentWindow on iframe');
    }
  };

  // TODO: type messages data so we can remove any from here
  sendMessageToMerchant = (event: Event, value: any) => {
    this.eventListeners[event].forEach((listener: Listener) => {
      listener(value);
    });
  };
}
